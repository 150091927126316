//@ts-check
import takeRecords from '../utils/takeRecords.js';
import {addEventListener} from '../utils/windowEvents.js';
import {getPn} from './pages.js';
import {entryType as lcpEntryType, lcpResult} from './lcp.js';
import cls from './cls.js';

const entryType = 'crux';

let helpers;

/**
 * Compute LCP and CLS as close as possible to CrUX
 * @param {import('../utils/utils.js').State} state
 * @param {Promise<{startTime: number}>} visibility
 * @returns {Promise<{entryType: string, startTime: number, pn: number} & import('./lcp.js').LargestContentfulPaintResult & import('./cls.js').LayoutShiftResult>}
 */
export default function crux(state, visibility) {
    const [window, , PerformanceObserver] = state;

    let hidden = 1000000;
    visibility.then(({startTime}) => hidden = startTime);

    helpers = {
        cls: () => cls(state, true),
        lcp() {
            const entries = /** @type {Array<import('./lcp.js').LargestContentfulPaint>} */(takeRecords(PerformanceObserver, lcpEntryType)?.filter(({startTime}) => startTime < hidden));
            return entries?.pop();
        }
    };

    return new Promise(resolve => {
        addEventListener(window, 'beforeunload', ({timeStamp}) => {
            const result = {
                entryType,
                startTime: timeStamp,
                pn: getPn()
            };

            const lcp = lcpResult(helpers.lcp());
            resolve(Object.assign(result, lcp, cls(state)));
        }, true);
    });
}

export function addGetters(target) {
    const getElement = op => () => helpers?.[op]()?.element;

    return Object.defineProperties(target, {
        lcp: {
            value: getElement('lcp')
        },
        cls: {
            value: getElement('cls')
        }
    });
}
